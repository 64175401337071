import React from "react";
import { useNavigate } from "react-router-dom";
import { Document, Page, View, Text, PDFViewer } from "@react-pdf/renderer";

import global from "global";
import transcript from "utils/transcript";
import prettierNumber from "utils/prettier-number";
import { useSelector } from "store/hooks";
import { OCRPdf, pdfStyles } from "pages/ocr";
import ResultBox from "components/result-box";
import styles from "./result.module.scss";

const Result = () => {
  const contextApplication = useSelector((state) => state.application);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (contextApplication.data === null) navigate("/");
  }, [contextApplication, navigate]);

  return (
    <div className={styles.result}>
      <div className={styles.pdf}>
        <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
          <ResultPdf contextApplication={contextApplication} />
        </PDFViewer>
      </div>
      <ResultBox />
    </div>
  );
};

export const ResultPdf = ({
  contextApplication,
}: {
  contextApplication: global.contextApplication;
}) => {
  const selectedRecommendation = React.useMemo(() => {
    return contextApplication.data?.recommendations_log?.find(
      (rec) => rec.id === contextApplication.data?.selected_recommendation
    );
  }, [
    contextApplication.data?.selected_recommendation,
    contextApplication.data?.recommendations_log,
  ]);

  if (!contextApplication.data) return <></>;

  const { first_name, last_name, email } =
    contextApplication.data.created_by ?? {};
  return (
    <Document>
      {contextApplication.data?.scoring_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Результат скоринга</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.text}>
              <Text>
                Скоринг проведён экспертом:{" "}
                {[first_name, last_name, "(" + email + ")"].join(" ")}
              </Text>
              <Text>
                Дата проведения скоринга:{" "}
                {new Date(
                  contextApplication.data.scoring_log.created_at
                ).toLocaleString()}
              </Text>
            </View>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>№ заявления по микрокредиту</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.user_id}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Виды залога</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.collateral(contextApplication.data.collateral)}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Сумма</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(
                      selectedRecommendation?.amount ??
                        contextApplication.data?.amount ??
                        0
                    )}{" "}
                    ₸
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Длительность</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {selectedRecommendation?.duration ??
                      contextApplication.data?.duration}{" "}
                    мес.
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Процентная ставка</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data?.interest_rate} %</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Метод оплаты</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.paymentMethod(
                      contextApplication.data.payment_method
                    )}
                  </Text>
                </View>
              </View>
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Рейтинг клиента в БКИ</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.cb_score}</Text>
                </View>
              </View> */}
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Филиал</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.branch(
                      contextApplication.data.scoring_log.branch
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>LTV</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.ltv}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Рыночная стоимость</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(
                      contextApplication.data.scoring_log.market_cost
                    )}{" "}
                    ₸
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Ликвидная стоимость</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(
                      contextApplication.data.scoring_log.liquid_cost
                    )}{" "}
                    ₸
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Ежемесячный доход клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {prettierNumber(
                      contextApplication.data.scoring_log.income_amount
                    )}{" "}
                    ₸
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Пол</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.gender(
                      contextApplication.data.scoring_log.gender
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Возраст</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.age}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Семейное положение</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.marital_status(
                      contextApplication.data.scoring_log.marital_status
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Цикл клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.type_of_prev_credits}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Количество иждивенцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.scoring_log.dependants}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Опыт</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.work_experience(
                      contextApplication.data.scoring_log.work_experience
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Статус деятельности</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.occupation(
                      contextApplication.data.scoring_log.occupation
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Род деятельности</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {transcript.occupation_area(
                      contextApplication.data.scoring_log.occupation_area
                    )}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие машины</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.has_car
                      ? "Есть"
                      : "Нет"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Наличие дома</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.has_house
                      ? "Есть"
                      : "Нет"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Поручитель/Созаемщик</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.scoring_log.co_borrower
                      ? "Есть"
                      : "Нет"}
                  </Text>
                </View>
              </View>
            </View>
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                Результат скоринга:{" "}
                {contextApplication.data?.is_approved
                  ? "Пройден"
                  : "Не пройден"}{" "}
                {Math.round(
                  (selectedRecommendation?.good_prob ??
                    contextApplication.data?.scoring_log?.prediction) * 100
                )}
                %
              </Text>
              <Text style={pdfStyles.result}>
                Порог:{" "}
                {(
                  contextApplication.data?.scoring_log?.threshold * 100
                ).toFixed()}
                %
              </Text>
            </View>
          </View>
        </Page>
      )}
      {contextApplication.data?.ocr_log && (
        <OCRPdf contextApplication={contextApplication} />
      )}
      {contextApplication.data.cdn_log && (
        <Page style={pdfStyles.page}>
          <View style={pdfStyles.heading}>
            <Text>Отчёт по КДН</Text>
          </View>
          <View style={pdfStyles.section}>
            <View style={pdfStyles.table}>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Ф.И.О. клиента</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.cdn_log.full_name ?? "-"}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Расход заемщика по ВПМ</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {
                      contextApplication.data.cdn_log
                        .client_income_by_subsistence
                    }
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Количество иждивиенцев</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.cdn_log.dependants}</Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>ВПМ</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.cdn_log.subsistence_level}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Коэффицент</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.cdn_log.subsistence_quotient}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Доход заёмщика</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.cdn_log.income_last_6_months}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Платежи по кредитам (другие МФО/банки)</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.cdn_log.other_banks_payments}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Платежи по кредитам SwissCapital</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    {contextApplication.data.cdn_log.swiss_monthly_payment}
                  </Text>
                </View>
              </View>
              <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Расчет КДН</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>{contextApplication.data.cdn_log.cdn.toFixed(3)}</Text>
                </View>
              </View>
              {/* <View style={pdfStyles.tr}>
                <View style={pdfStyles.td}>
                  <Text>Комментарии по доходам</Text>
                </View>
                <View style={pdfStyles.td}>
                  <Text>
                    Доход клиента высчитан из выписки Каспи голд за период:{" "}
                    {new Date(
                      contextApplication.data.ocr_log.starting_date
                    ).toLocaleDateString()}{" "}
                    –{" "}
                    {new Date(
                      contextApplication.data.ocr_log.end_date
                    ).toLocaleDateString()}
                  </Text>
                </View>
              </View> */}
            </View>
          </View>
          {contextApplication.data.cdn_log !== null && (
            <View style={pdfStyles.bottom}>
              <Text style={pdfStyles.result}>
                Результат КДН:{" "}
                {contextApplication.data.cdn_log?.is_approved
                  ? "Пройден"
                  : "Не пройден"}
              </Text>
            </View>
          )}
          <View style={pdfStyles.text2}>
            <Text>
              КДН проведён экспертом:{" "}
              {[first_name, last_name, "(" + email + ")"].join(" ")}
            </Text>
            <Text>
              Дата проведения КДН:{" "}
              {new Date(
                contextApplication.data.cdn_log.created_at
              ).toLocaleString()}
            </Text>
            <Text style={pdfStyles.text2}>Подпись: _______________</Text>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
